<template>
  <tr>
    <td class="text-center">{{ item.id }}</td>
    <td class="text-center">{{ item.name }}</td>
    <td class="text-center">{{ item.growing_percentage }}</td>
    <td class="text-center">{{ maxGrowingAgeLine }}</td>
    <td class="text-center">{{ item.created_at }}</td>
    <td class="text-center">
      {{ isActive }}
    </td>
    <td class="text-right"></td>
  </tr>
</template>
<script>
  export default {
    name: 'NomenclatureItem',
    props: {
      item: {
        type: Object,
        required: true,
      },
    },
    computed: {
      maxGrowingAgeLine () {
        return this.item.max_growing_age ? this.item.max_growing_age : 'Not Set'
      },
      isActive () {
        return this.item.is_active ? 'Yes' : 'No'
      },
    },
  }
</script>
